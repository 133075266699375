import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class ClientesService {
  listarCLientes () {
    return http.get(`${baseUrl}/ov-cliente/listar`, {
      headers: {
        loading: true
      }
    })
  }

  search (search = null) {
    return http.get(`${baseUrl}/ov-cliente/search${search ? `/${search}` : ''}`, {
      headers: {
        loading: true
      }
    })
  }
}
